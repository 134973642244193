import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import './registerServiceWorker'
import VuecmfEditor from 'vue-vuecmf-editor'
import 'jodit/build/jodit.min.css'
// vue-gtag
import VueGtag from 'vue-gtag'
// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import JoditVue from 'jodit-vue'
// .use(JoditVue)
const app = createApp(App)
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('ins')
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VuecmfEditor)
app.use(VueGtag, {
  config: { id: 'G-2JKW9LQSTL' }
})
// element-plus
app.use(ElementPlus)
app.mount('#app')
// createApp(App).use(store).use(router).use(VueAxios, axios).use(VuecmfEditor).component('QuillEditor', QuillEditor).mount('#app')

router.beforeEach((to, from, next) => {
  // console.log('to', to, 'from', from, 'next', next)
  // ...
  if (to.meta.requiresAuth) {
    // console.log('這裏需要驗證')
    // this.defaults.headers.common['Authorization'] =AUTH_TOKEN
    const myCookie = document.cookie.replace(/(?:(?:^|.*;\s*)user\s*=\s*([^;]*).*$)|^.*$/, '$1')
    // console.log('myCookie', myCookie)
    if (myCookie) {
      // console.log('驗證 OK')
      // 放行 next()
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    // console.log('這裏不需要驗證')
    next()
  }
})
