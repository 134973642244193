import { createStore } from 'vuex'

// 為 store state 聲明類型
export interface session {
  kcToken: string;
  role: string;
}
export interface State {
  session: null | session[];
}

export default createStore<State>({
  state: {
    session: null
  },
  getters: {
    getSession: state => {
      return state.session
    }
  },
  mutations: {
    updateSession (state, keyRole) {
      state.session = keyRole
    }
  },
  actions: {
  },
  modules: {
  }
})
