
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component'
const Islogin = ref(false)
const IsAdmin = ref(false)
@Options({
  data () {
    return {
      userName: '',
      Islogin,
      IsAdmin
    }
  },
  state: {},
  mounted () {
    // Js code to make color box enable or disable
    const body = document.querySelector('body')
    const colorIcons = document.querySelector('.color-icon')
    const sidebarOpen = document.querySelector('.sidebar-open')
    const sidebarClose = document.querySelector('.siderbar-close')
    const nav = document.querySelector('nav')
    // 尋找 cookie 是否有 color
    const myColor = document.cookie.replace(/(?:(?:^|.*;\s*)color\s*=\s*([^;]*).*$)|^.*$/, '$1')
    const WebColor = myColor.split(',')
    if (myColor) {
      const root = document.querySelector(':root')
      if (root) {
        (root as HTMLModElement).style.setProperty('--white', WebColor[0]);
        (root as HTMLModElement).style.setProperty('--black-color', WebColor[1]);
        (root as HTMLModElement).style.setProperty('--light-bg', WebColor[2]);
        (root as HTMLModElement).style.setProperty('--black', WebColor[3]);
        (root as HTMLModElement).style.setProperty('--color1', WebColor[4]);
        (root as HTMLModElement).style.setProperty('--color2', WebColor[5]);
        (root as HTMLModElement).style.setProperty('--color3', WebColor[6]);
        (root as HTMLModElement).style.setProperty('--color4', WebColor[7])
        // console.log('myColor:' + myColor)
        // console.log('WebColor:' + WebColor)
      }
    }
    // const icons = document.querySelector('.color-icon .icons')
    if (colorIcons) {
      // getting all .btn elements
      const buttons = document.querySelectorAll('.btn')
      for (const button of buttons) {
        button.addEventListener('click', (e) => {
          // adding click event to each button 給每個按鈕添加點擊事件
          const target = e.target
          if (target) {
            const open = document.querySelector('.open')
            if (open) open.classList.remove('open')
            document.querySelector('.active')?.classList.remove('active');
            (target as HTMLModElement).classList.add('active')
            // js code to switch colors (also day night mode) js代碼切換顏色（也是晝夜模式）
            const root = document.querySelector(':root')
            const dataColor = (target as HTMLModElement).getAttribute(
              'data-color'
            ) // getting data-color values of clicked button 獲取單擊按鈕的數據顏色值
            const color = dataColor?.split(' ') // splitting each color from space and make them array 從空間中分離每種顏色並使它們成為數組
            // passing particular value to a particular root variable 將特定值傳遞給特定的根變量
            if (root && color) {
              (root as HTMLModElement).style.setProperty('--white', color[0]);
              (root as HTMLModElement).style.setProperty('--black-color', color[1]);
              (root as HTMLModElement).style.setProperty('--light-bg', color[2]);
              (root as HTMLModElement).style.setProperty('--black', color[3]);
              (root as HTMLModElement).style.setProperty('--color1', color[4]);
              (root as HTMLModElement).style.setProperty('--color2', color[5]);
              (root as HTMLModElement).style.setProperty('--color3', color[6]);
              (root as HTMLModElement).style.setProperty('--color4', color[7])
              // cookie 儲存顏色
              document.cookie = `color = ${color};`
            }
            const iconName = (target as HTMLModElement).className.split(' ')[2] // getting the class name of icon

            const coloText = document.querySelector('.home-content span')
            if ((target as HTMLModElement).classList.contains('fa-moon')) {
              // if icon name is moon 如果圖標名稱是月亮
              (target as HTMLModElement).classList.replace(iconName, 'fa-sun');
              // replace it with the sun 用太陽代替它;=
              (colorIcons as HTMLModElement).style.display = 'none'
              if (coloText) {
                coloText.classList.add('darkMode')
              }
            } else if (
              (target as HTMLModElement).classList.contains('fa-sun')
            ) {
              // if icon name is sun 如果圖標名稱是太陽
              (target as HTMLModElement).classList.replace('fa-sun', 'fa-moon'); // replace it with the sun
              (colorIcons as HTMLModElement).style.display = 'block'
              if (coloText) {
                coloText.classList.remove('darkMode')
              }
              (document.querySelector('.btn.green') as HTMLModElement).click()
            }
          }
        })
      }
    }
    if (sidebarOpen) {
      sidebarOpen.addEventListener('click', () => {
        if (nav) {
          nav.classList.add('active')
        }
      })
    }
    if (sidebarClose) {
      sidebarClose.addEventListener('click', () => {
        if (nav) {
          nav.classList.remove('active')
        }
      })
    }
    if (body) {
      body.addEventListener('click', (e) => {
        const clickedElm = e.target
        if (clickedElm) {
          if (
            !(clickedElm as HTMLModElement).classList.contains('sidebar-open') &&
            !(clickedElm as HTMLModElement).classList.contains('menu') &&
            !(clickedElm as HTMLModElement).classList.contains('icons') &&
            !(clickedElm as HTMLModElement).classList.contains('fas') &&
            !(clickedElm as HTMLModElement).classList.contains('color-box') &&
            !(clickedElm as HTMLModElement).classList.contains('color-title')
          ) {
            if (nav) {
              nav.classList.remove('active')
            }
          }
        }
      })
    }
  },
  created () {
    // console.log(this.$route.params.id)
    // console.log('logo in : ', this.$store.getters.getSession)
    const myCookie = document.cookie.replace(/(?:(?:^|.*;\s*)user\s*=\s*([^;]*).*$)|^.*$/, '$1')
    if (myCookie) {
      this.$store.commit('updateSession', { token: myCookie, role: '1' })
      this.userName = myCookie
      if (myCookie === 'kenchan1993') {
        IsAdmin.value = true
      } else {
        IsAdmin.value = false
      }
    }
    if (this.$store.getters.getSession == null) {
      Islogin.value = false
    } else {
      Islogin.value = true
    }
  },
  methods: {
    openicons () {
      // 開啓顏色選擇
      const colorIcons = document.querySelector('.color-icon')
      colorIcons?.classList.toggle('open')
    },
    color () {
      // 顏色選擇
      // console.log('color')
    },
    logout () {
      // console.log(this.$store.getters.getSession)
      Islogin.value = false
      IsAdmin.value = false
      this.$store.commit('updateSession', null)
      // 如果您不指定路徑，某些瀏覽器將不允許您刪除 cookie。
      document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = 'kcToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      this.$router.push({ path: '/' })
    },
    backstage () {
      // this.$router.push({ path: '/' })
    },
    setup () {
      return {
        Islogin,
        IsAdmin
      }
    }
  }
})
export default class TopMenuView extends Vue {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  Islogin: any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
IsAdmin: any
backstage: ((payload: MouseEvent) => void) | undefined
logout: ((payload: MouseEvent) => void) | undefined
// eslint-disable-next-line @typescript-eslint/no-explicit-any
userName: any
openicons: ((payload: MouseEvent) => void) | undefined
}
