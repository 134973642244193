
import { Options, Vue } from 'vue-class-component'
@Options({
  methods: {
    gohome: function () {
      this.$router.push('/')
    }
  }
})
export default class PageNotFound extends Vue {
  gohome: any;
}
