<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  created () {
    // console.log('狀態' + process.env.VUE_APP_NAME)
  }
})
</script>

<style lang="scss">

</style>
