import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import TopMenuView from '@/components/TopMenuView.vue'
import BottomMenuView from '@/components/BottomMenuView.vue'
import PageNotFound from '@/components/PageNotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/IndexView.vue'),
    meta: { title: '驚青網', keywords: '驚青網,驚青論壇,遊戲', description: '一個與遊戲結合的討論區' }
  },
  {
    path: '/topMenu',
    name: 'TopMenu',
    component: TopMenuView
  },
  {
    path: '/bottomMenu',
    name: 'BottomMenu',
    component: BottomMenuView
  },
  {
    path: '/news',
    name: 'newsList',
    component: () => import(/* webpackChunkName: "newsList" */ '@/views/NewsListView.vue'),
    meta: { title: '驚青網-新聞', keywords: '驚青網,新聞,遊戲', description: '驚青網新聞' }
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '@/views/NewsView.vue'),
    meta: { title: '驚青網-新聞' }
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import(/* webpackChunkName: "tools" */ '@/views/Tools/ToolsListView.vue'),
    meta: { title: '驚青網-小工具' }
  },
  {
    path: '/case-conversion',
    name: 'CaseConversion',
    component: () => import(/* webpackChunkName: "tools" */ '@/views/Tools/CaseConversionView.vue'),
    meta: { title: '驚青網-英文大小寫轉換', keywords: '驚青網,工具,英文大小寫轉換', description: '英文大小寫轉換工具' }
  },
  {
    path: '/random-password',
    name: 'RandomPassword',
    component: () => import(/* webpackChunkName: "tools" */ '@/views/Tools/RandomPasswordView.vue'),
    meta: { title: '驚青網-密碼生成器', keywords: '驚青網,工具,密碼生成器', description: '密碼生成器工具' }
  },
  {
    path: '/friends-link',
    name: 'friends-link',
    component: () => import(/* webpackChunkName: "friends-link" */ '@/views/FriendsLinkView.vue'),
    meta: { title: '驚青網-友情連結' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    meta: { title: '驚青網' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPageView.vue'),
    meta: { title: '驚青網-登錄' }
  },
  {
    path: '/lol',
    name: 'lol',
    component: () => import(/* webpackChunkName: "lol" */ '@/views/LeagueOfLegendsView.vue'),
    meta: { title: '驚青網-找隊友' }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin/AdminListView.vue'),
    meta: { title: '驚青網-管理後台', requiresAuth: true }
  },
  {
    path: '/admin/news/',
    name: 'adminNews',
    component: () => import(/* webpackChunkName: "news" */ '@/views/Admin/AdminNewsListView.vue'),
    meta: { title: '驚青網-管理後台 新聞', requiresAuth: true }
  },
  {
    path: '/admin/news/add',
    name: 'adminNewsAdd',
    component: () => import(/* webpackChunkName: "news" */ '@/views/Admin/NewsAddView.vue'),
    meta: { title: '驚青網-管理後台 新聞add', requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pagenotfound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    (document.title as unknown) = to.meta.title
  }
  if (to.meta.keywords) {
    (document.querySelector('meta[name="keywords"]') as HTMLModElement).setAttribute('content', (to.meta.keywords as string))
  }
  if (to.meta.description) {
    (document.querySelector('meta[name="description"]') as HTMLModElement).setAttribute('content', (to.meta.description as string))
  }
  next()
})
export default router
