
export default {
  state: {},
  methods: {
    openicons () {
      // 開啓顏色選擇
      const colorIcons = document.querySelector('.color-icon')
      colorIcons?.classList.toggle('open')
    },
    color () {
      // 顏色選擇
      // console.log('color')
    }
  }
}
